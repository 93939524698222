const serviceApp = process.env.VUE_APP_CREATIVES_SERVICE;

export default {
  methods: {
    async getCreativeId(idCreative) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/v2/creative/${idCreative}`);
    },

    async getCreativeBannersSizes() {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/get/banner_size/filter`);
    },

    async getCreativeCategory() {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/get/category/filter`);
    },

    async getCreativeOffer() {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/get/offer/filter`);
    },

    async createCreativeWithParams(
      campaignId,
      idCreativeType,
      activationDateEn,
      idOffer,
      idCategory,
      destinationUrl,
      htmlContent,
      description,
      idSize,
      imageUrl,
      expirationDateEn,
      title,
      code,
      subject,
      sender
    ) {
      let bodyJson = {
        id_campaign: `"${campaignId}"`,
        id_type: `"${idCreativeType}"`,
        activation_date: `"${activationDateEn}"`
      };

      bodyJson = typeof idOffer !== 'undefined' ? [...bodyJson, ...{ id_offer: `"${idOffer}"` }] : bodyJson;
      bodyJson = typeof idCategory !== 'undefined' ? [...bodyJson, ...{ id_category: `"${idCategory}"` }] : bodyJson;
      bodyJson =
        typeof destinationUrl !== 'undefined' ? [...bodyJson, ...{ destination_url: `"${destinationUrl}"` }] : bodyJson;
      bodyJson = typeof htmlContent !== 'undefined' ? [...bodyJson, ...{ html: `"${htmlContent}"` }] : bodyJson;
      bodyJson = typeof description !== 'undefined' ? [...bodyJson, ...{ description: `"${description}"` }] : bodyJson;
      bodyJson = typeof idSize !== 'undefined' ? [...bodyJson, ...{ id_size: `"${idSize}"` }] : bodyJson;
      bodyJson = typeof imageUrl !== 'undefined' ? [...bodyJson, ...{ image_url: `"${imageUrl}"` }] : bodyJson;
      bodyJson =
        typeof expirationDateEn !== 'undefined'
          ? [...bodyJson, ...{ expiration_date: `"${expirationDateEn}"` }]
          : bodyJson;
      bodyJson = typeof title !== 'undefined' ? [...bodyJson, ...{ title: `"${title}"` }] : bodyJson;
      bodyJson = typeof code !== 'undefined' ? [...bodyJson, ...{ code: `"${code}"` }] : bodyJson;
      bodyJson = typeof subject !== 'undefined' ? [...bodyJson, ...{ subject: `"${subject}"` }] : bodyJson;
      bodyJson = typeof sender !== 'undefined' ? [...bodyJson, ...{ sender: `"${sender}"` }] : bodyJson;

      return await this.$http.post(`/v2/redirect`, {
        app: `${serviceApp}`,
        path: '/api/create/creative',
        params: {
          data: bodyJson
        }
      });
    },

    async createCreative(creativeParams) {
      return await this.$http.post(`/v2/redirect`, {
        app: serviceApp,
        path: '/api/create/creative',
        params: {
          data: creativeParams
        }
      });
    },

    async editCreative(idCreative, creativeParams) {
      return await this.$http.put(`/v2/redirect`, {
        app: serviceApp,
        path: `/api/edit/creative/${idCreative}`,
        params: {
          data: creativeParams
        }
      });
    },

    async editCreativeWithImageFile(idCreative, creativeParams, fileImage) {
      let formData = new FormData();

      Object.entries(creativeParams).forEach((value) => {
        if (value[0] !== 'image_url') {
          formData.append(value[0], value[1]);
        } else {
          formData.append('image_url', fileImage);
        }
      });

      // return await this.$http.put(
      //     `/v2/redirect`,
      //     {
      //         "app": serviceApp,
      //         "path": `/api/edit/creative/${idCreative}`,
      //         "params": {
      //             "data": creativeParams
      //         }
      //     },
      //     {
      //         headers: {
      //             "Content-Type": "multipart/form-data"
      //         },
      //     }
      // );
    },

    async upload(idCampaign, fileImage) {
      let formData = new FormData();

      formData.append('image', fileImage);
      formData.append('campaign_id', idCampaign);

      return await this.$http.post('/v2/upload/banner_image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },

    async listCampaignCreativeTypes(campaignId) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/get/creative_type/campaign/${campaignId}`);
    },

    async listCreativeTypes() {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/get/creative_type`);
    },

    async editStatus(statusCreative, idCreative) {
      return await this.$http.put(`/v2/redirect`, {
        app: `${serviceApp}`,
        path: `/api/edit/setstatus/creative/`,
        params: {
          data: {
            status: statusCreative,
            idCreative: idCreative
          }
        }
      });
    },

    async advancedSearch(page, perPage, filterCreative) {
      let queryString, queryStringUrl;

      if (typeof filterCreative === 'undefined') queryString = '';
      else queryString = `${filterCreative}`;

      if (!page) queryString += '&page=1';
      else queryString += page;

      if (!perPage) queryString += '&perPage=10';
      else queryString += perPage;

      queryStringUrl = encodeURI(queryString);

      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/get/creative/search/?${queryStringUrl}`);
    },

    async searchCreativesAffiliate(page, perPage, filterCreative) {
      let queryString, queryStringUrl;

      if (typeof filterCreative === 'undefined') queryString = '';
      else queryString = `${filterCreative}`;

      if (!page) queryString += '&page=1';
      else queryString += page;

      if (!perPage) queryString += '&perPage=10';
      else queryString += perPage;

      queryStringUrl = encodeURI(queryString);

      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/search/creative/affiliate?${queryStringUrl}`
      );
    },

    async getSeasonality() {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/get/offer`);
    },

    async getShortned(creativeId, channelId) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/get/findshortned/${creativeId}/${channelId}`
      );
    },

    async getShortnedV2(creativeId, siteId) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/get/affiliate/shortned/${creativeId}/${siteId}`
      );
    },

    async uploadBanner(form) {
      return await this.$http.post('/v2/upload/file', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },

    async approveAdvertiserCreative(creativeId) {
      return await this.$http.put(`/v2/redirect`,
        {
          app: `${serviceApp}`,
          path: '/api/approve/creative/' + creativeId,
          params: {
            data: {}
          }
      });
    },
    async setStatusCreativeLot(status, creatives) {
      return await this.$http.put(`/v2/redirect`,
        {
          app: serviceApp,
          path: '/api/edit/setstatus/creative/lot',
          params: {
            data: {
              status,
              creatives
            }
        }
      });
    }
  }
};
